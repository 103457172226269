<template>
  <div class="row mx-0">
    <div class="col-12 py-2 px-2 p-md-3" :class="{'lightgrey_bg': isOdd}">
      <div class="row align-items-center">
        <div class="col-8 col-md p-md-0 text-md-center font15 text-truncate">
          {{item.registeredName}}
        </div>
        <div class="col p-0 text-center font15 d-none d-md-block">
          {{adminDetails}}
        </div>
        <!-- <div class="col p-0 text-center font15">
          {{date}}
        </div> -->
        <div class="col p-0 text-center font15 d-none d-md-block">
          <Button color="green" btnText="View Donations" size="small" @click="view" />
        </div>
        <div class="col-4 col-md-3 p-0 text-center">
          <div class="row mx-0 align-items-center justify-content-end d-none d-md-flex">
            <div class="col ">
              <Button color="gold" btnText="Edit Organisation" size="small" icon="arroe" @click="editOrg">
                <IconEdit size="size16" />
              </Button>
            </div>
            <div class="col-auto px-1">
              <Button color="darkgrey_red" icon="arrow" size="xxsmall" @click="deleteOrg">
                <ios-close-icon class="font19 inline-icon" />
              </Button>
            </div>
          </div>
          <div class="row mx-0 align-items-center justify-content-end d-md-none">
            <div class="col p-0">
              <Button color="green" btnText="EDIT" size="small" @click="editOrg"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconEdit: defineAsyncComponent(() => import('@/components/icons/IconEdit.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue'))
  },
  name: 'PastDonationItem',
  props: ['item', 'thekey'],
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      isSelected: false,
      adminDetails: ''
    }
  },
  async beforeMount () {
    this.adminDetails = await this.fetchOrganisationAdmin(this.item.userID)
  },
  computed: {
    // date () {
    //   const date = DateTime.fromMillis(parseInt(this.item.transactionDate))
    //   return date.toLocaleString(DateTime.DATE_FULL)
    // },
    isOdd () {
      return this.thekey % 2
    }
  },
  methods: {
    ...mapActions(['fetchOrganisationAdmin']),
    view () {
      this.$emit('viewDonations')
    },
    deleteOrg () {
      this.$emit('deleteOrg')
    },
    editOrg () {
      this.$emit('editOrg')
    }
  }
}
</script>

<style scoped>
.font15 {
  font-size: 13px;
}
@media (min-width: 992px) {
  .font15 {
    font-size: 15px;
  }
}
</style>
